function buildEngineDescription(listing) {
    const {
        displacementUOM,
        engine: { name: engine } = {},
        fuelType: { name: fuelType } = {},
    } = listing;

    let description = '';

    if (fuelType && fuelType.toLowerCase() === 'hydrogen') {
        return `${fuelType} ${engine} Electric`;
    }

    if (!engine || engine && (['electric', 'hybrid'].includes(engine.toLowerCase()))) {
        if (fuelType) {
            description = fuelType;
        }
    } else {
        if (displacementUOM) {
            // format displacementUOM to have atleast a decimal number, ex: 3 -> 3.0
            description = `${Number.isInteger(displacementUOM) ? displacementUOM.toFixed(1) : displacementUOM}L`;
        }

        if (engine) {
            description += ` ${engine}`;
        }

        if (fuelType) {
            description += ` ${fuelType.toLowerCase() === 'gasoline' ? 'Gas' : fuelType}`;
        }
        // remove redundant space when displacementUOM is empty
        description = description.trim();
    }

    if (description === 'Electric') {
        return description;
    }

    return description ? `${description} Engine` : '';
}

export default function buildSpecifications(listing) {
    const specifications = {};

    const {
        color: { exteriorColor, interiorColor } = {},
        driveType: { description: driveType } = {},
        electricComponentInfo,
        electricVehicleRange,
        fuelType: { name: fuelType } = {},
        hasLeatherSeats,
        mileage: { value: mileage } = {},
        transmission: { description: transmission } = {},
        truckBedLength,
        truckBedSizeDescription,
    } = listing;

    if (driveType) {
        specifications.driveType = {
            label: 'DRIVE TYPE',
            value: driveType,
        };
    }

    if (electricComponentInfo?.epaChargeTimeAt240V) {
        specifications.evBatteryChargeTime = {
            value: `Est. ${electricComponentInfo.epaChargeTimeAt240V} hr Charging Time`,
        };
    }

    if (electricComponentInfo?.batteryDegradationInfo && electricComponentInfo?.batteryDegradationInfo?.ratingSource !== 'Manheim Average'
        && electricComponentInfo?.batteryDegradationInfo?.minRange && electricComponentInfo.batteryDegradationInfo.minRange > 0
        && electricComponentInfo?.batteryDegradationInfo?.maxRange && electricComponentInfo.batteryDegradationInfo.maxRange > 0) {
        specifications.batteryDegradationInfo = {
            label: 'USABLE RANGE',
            value: `Est. ${electricComponentInfo.batteryDegradationInfo.minRange}-${electricComponentInfo.batteryDegradationInfo.maxRange} mi. Usable Range`,
        };
    } else if (fuelType && electricVehicleRange > 0) {
        const range = {
            label: 'EV RANGE',
            // format number with commas, ex 1234567 -> 1,234,567 Mile Range
            value: `${electricVehicleRange.toLocaleString('en-US')} EV Mile Range When New`,
        };

        if (['electric', 'hybrid gas/electric'].includes(fuelType.toLowerCase())) {
            specifications.batteryDegradationInfo = range;
        }
    }

    specifications.engineDescription = {
        label: 'ENGINE_DESCRIPTION',
        value: buildEngineDescription(listing),
    };

    if (exteriorColor) {
        specifications.exterior = {
            label: 'EXTERIOR',
            value: exteriorColor,
        };
    }

    if (interiorColor) {
        specifications.interiorSeats = {
            label: 'INTERIOR_SEATS',
            value: `${interiorColor}${hasLeatherSeats ? ' Leather Seats' : ''}`,
        };
    }

    if (mileage) {
        specifications.mileage = {
            label: 'MILEAGE',
            value: mileage,
        };
    }

    if (transmission) {
        specifications.transmission = {
            label: 'TRANSMISSION',
            value: transmission,
        };
    }

    if (truckBedLength) {
        specifications.truckBedLength = {
            label: 'BED LENGTH',
            value: truckBedLength,
        };
    }

    if (truckBedSizeDescription) {
        specifications.truckBedSizeDescription = {
            label: 'BED SIZE DESCRIPTION',
            value: truckBedSizeDescription,
        };
    }

    return specifications;
}
